import React, { useEffect } from "react";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import {
  updateUserDependencies,
  updateUserInfo,
  updateUserPrecautions,
  updateUserStatements,
  updateUserSymptoms,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import results from "../../constants/results";
import {FormattedMessage} from "react-intl";
import {isMobile} from 'react-device-detect';
import {symptoms} from "../../constants";

export default function Results(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const dispatch = useDispatch();
  const user = useSelector((state) => {
    return state;
  });
  const contactInformation = [
    "https://moz.gov.ua",
    "https://t.me/mozofficial",
    "https://www.facebook.com/moz.ukr",
  ];
  const phones = [
    { name: <FormattedMessage id='result.phones.emergency' defaultMessage="Швидка" />, detail: "103" },
    { name: <FormattedMessage id='result.phones.moz' defaultMessage="Урядова гаряча лінія" />, detail: "1545" },
    { name: <FormattedMessage id='result.phones.emergencyHelp' defaultMessage="Надання екстреної медичної допомоги" />, detail: "(044) 518 47 11" },
  ];



  const handleRepeat = () => {
    localStorage.removeItem("MoniHeal data");

    // dispatch(clearUserInfo());

    dispatch(updateUserInfo({ gender: "" }));
    dispatch(updateUserInfo({ age: 30 }));
    dispatch(updateUserPrecautions([]));
    dispatch(updateUserStatements([]));
    dispatch(updateUserSymptoms([], 0)); // TODO менять за текущий день
    dispatch(updateUserDependencies([], 0));
    dispatch(updateUserSymptoms([], 1)); // TODO менять за текущий день
    dispatch(updateUserDependencies([], 1));
    dispatch(updateUserSymptoms([], 2)); // TODO менять за текущий день
    dispatch(updateUserDependencies([], 2));
    dispatch(updateUserSymptoms([], 3)); // TODO менять за текущий день
    dispatch(updateUserDependencies([], 3));
    dispatch(updateUserSymptoms([], 4)); // TODO менять за текущий день
    dispatch(updateUserDependencies([], 4));
    props.handleActualStep(0, 0, 1);
  };

  const calculateEmergencyLevel = (state) => {
    let emergencyLevel = 0;
    let currentDaySymptomsEmergencyLevel = 0;
    let allSymptoms = state.user.synopsis[props.day - 1].checked;
    let allDependencies = state.user.synopsis[props.day - 1].dependencies;

    function isHaveFirstDaySymptoms() {
      let result = state.user.synopsis[0].checked.indexOf("common.nothing.above");
      return result === -1;
    }

    if (props.day === 1 && !isHaveFirstDaySymptoms()) {
      emergencyLevel = 5;
      return emergencyLevel;
    }

    function wasInContact() {
      let result = state.user.precautions2.checked.find((element) => {
        return element === 'common.nothing.above'
      });
      return result !== 'common.nothing.above';
    }

    function isChronicDiseasePresent() {
      let result = state.user.precautions1.checked.find((element) => {
        return element === 'common.nothing.above'
      });
      return result !== 'common.nothing.above';
    }

    function currentDaySymptomsEmergency(day) {
      if (isChronicDiseasePresent()) {
        state.user.synopsis[props.day - 1].addedSymptoms.forEach((item) => {
          let symptomObject = state.user.synopsis[0].data.find((element) => {
            return element.name === item;
          });
          if (symptomObject.weightWithChronic > emergencyLevel) {
            currentDaySymptomsEmergencyLevel = symptomObject.weightWithChronic;
          }
        });
      } else {
        state.user.synopsis[props.day - 1].addedSymptoms.forEach((item) => {
          let symptomObject = state.user.synopsis[0].data.find((element) => {
            return element.name === item;
          });
          if (symptomObject.weight > emergencyLevel) {
            currentDaySymptomsEmergencyLevel = symptomObject.weight;
          }
        });
      }
    }
    currentDaySymptomsEmergency(props.day);

    if (allDependencies.length === 0) {
      if (isChronicDiseasePresent()) {
        allSymptoms.forEach((item) => {
          let symptomObject = state.user.synopsis[0].data.find((element) => {
            return element.name === item;
          });
          if (symptomObject.weightWithChronic > emergencyLevel) {
            emergencyLevel = symptomObject.weightWithChronic;
          }
        });
      } else {
        allSymptoms.forEach((item) => {
          let symptomObject = state.user.synopsis[0].data.find((element) => {
            return element.name === item;
          });
          if (symptomObject.weight > emergencyLevel) {
            emergencyLevel = symptomObject.weight;
          }
        });
      }
    } else {
      if (isChronicDiseasePresent()) {
        allDependencies.forEach((item) => {
          let symptomObject = state.user.synopsis[0].data.find((element) => {
            return item.name === element.name;
          });
          let dependencyPosition = symptomObject.dependencyList.indexOf(item.value);


          if (symptomObject.dependencyWeightWithChronic[dependencyPosition] > emergencyLevel) {
            emergencyLevel = symptomObject.dependencyWeightWithChronic[dependencyPosition];
          }
        })
      } else {
        allDependencies.forEach((item) => {

          let symptomObject = state.user.synopsis[0].data.find((element) => {
            return item.name === element.name;
          });

          let dependencyPosition = symptomObject.dependencyList.indexOf(item.value);

          if (symptomObject.dependencyWeight[dependencyPosition] > emergencyLevel) {
            emergencyLevel = symptomObject.dependencyWeight[dependencyPosition];
          }
        })
      }
    }

    if(currentDaySymptomsEmergencyLevel > emergencyLevel && emergencyLevel <= 3) {
      emergencyLevel = currentDaySymptomsEmergencyLevel;
    }
    if (wasInContact() && emergencyLevel === 2) emergencyLevel++;

    return emergencyLevel;
  };

  let emergencyLevel = calculateEmergencyLevel(user);
  emergencyLevel = emergencyLevel !== 0 ? emergencyLevel -1 : 0;

  return (
    <React.Fragment>
      <Box mb={4}>
        <Typography variant="h6" align="center" gutterBottom>
          <FormattedMessage id='result.title' defaultMessage="Ваш результат" />
        </Typography>
        <Typography variant="subtitle1">
          <b><FormattedMessage id={results.results[emergencyLevel].title} defaultMessage={results.results[emergencyLevel].defaultMessage} /></b>
          <br />
          <FormattedMessage id={results.results[emergencyLevel].description} defaultMessage={results.results[emergencyLevel].defaultMessage} />
        </Typography>
      </Box>
      <Grid mt={4} container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>
            <FormattedMessage id='result.additionalInformation' defaultMessage="Додаткова інформація" />
          </Typography>
          <Typography gutterBottom><FormattedMessage id='result.mozContacts' defaultMessage="Контакти МОЗ України" /></Typography>
          {contactInformation.map((element, index) => (
            <React.Fragment key={index}>
              <a target="_blank" href={element}>{element}</a>
              <br />
            </React.Fragment>
          ))}
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>
            <FormattedMessage id='result.phones' defaultMessage="Телефони екстреної медичної допомоги в Україні" />
          </Typography>
          <Grid container>
            {phones.map((phones, index) => (
              <React.Fragment key={index}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{phones.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{phones.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <React.Fragment>
        <Grid
          container
          alignItems="flex-end"
          justify="space-between"
          spacing={10}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRepeat}
            >
              <FormattedMessage id='button.restart' defaultMessage='Пройти ще раз' />
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    </React.Fragment>
  );
}
