import { combineReducers } from 'redux';
import { user } from './user';
import { locale } from './locale';
import { error } from './error';
const rootReducer = combineReducers({
    user,
    locale,
    error
});

export default rootReducer;
