import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import App from './App';
import { addLocaleData } from 'react-intl';
import ru from "react-intl/locale-data/ru";
import uk from "react-intl/locale-data/uk";

addLocaleData(ru);
addLocaleData(uk);

ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>,
  document.querySelector('#root')
);
