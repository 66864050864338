import {
  UPDATE_USER_INFO,
  UPDATE_USER_PRECAUTIONS,
  UPDATE_USER_STATEMENTS,
  UPDATE_USER_SYMPTOMS,
  UPDATE_USER_CHANGES,
  UPDATE_USER_DEPENDENCIES,
  UPDATE_USER_STATE,
  UPDATE_USER_ADDED_SYMPTOMS,
} from "../action-types";

import {precautions1, precautions2, symptoms} from "../../constants";

const initialState = {
  gender: "",
  language: "uk",
  age: 30,
  precautions1: {data: precautions1, checked: []},
  precautions2: {data: precautions2, checked: []},
  isInContact: false,
  isChronicDiseasePresent: false,
  synopsis: [
    {data: symptoms, checked: [], dependencies: [], addedSymptoms: []},
    {data: symptoms, checked: [], dependencies: [], addedSymptoms: []},
    {data: symptoms, checked: [], dependencies: [], addedSymptoms: []},
    {data: symptoms, checked: [], dependencies: [], addedSymptoms: []},
    {data: symptoms, checked: [], dependencies: [], addedSymptoms: []},
  ],
  resultId: -1
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_STATE:
      return action.payload;
    case UPDATE_USER_INFO:
      return {...state, ...action.payload};
    case UPDATE_USER_PRECAUTIONS:
      return {
        ...state,
        precautions1: {...state.precautions1, checked: action.payload},
      };
    case UPDATE_USER_STATEMENTS:
      return {
        ...state,
        precautions2: {...state.precautions2, checked: action.payload},
      };
    case UPDATE_USER_SYMPTOMS:
      return {
        ...state,
        synopsis: state.synopsis.map((item, index) => {
          if (index === action.day) {
            item.checked = action.payload;
            return item
          } else return item;
        }),
      };
    case UPDATE_USER_ADDED_SYMPTOMS:
      return {
        ...state,
        synopsis: state.synopsis.map((item, index) => {
          if (index === action.day) {
            item.addedSymptoms = action.payload;
            return item
          } else return item;
        }),
      };
    case UPDATE_USER_DEPENDENCIES:
      return {
        ...state,
        synopsis: state.synopsis.map((item, index) => {
          if (index === action.day) {
            item.dependencies = action.payload;
            return item
          } else return item;
        }),
      };
    case UPDATE_USER_CHANGES:
      return {
        ...state,
        synopsis: state.synopsis.map((item, index) => {
          if (index === +action.payload.day) {
            return {
              ...item,
              changes: item.changes.map((item) =>
                item.id === action.payload.data.id
                  ? {...item, ...action.payload.data}
                  : item
              ),
            };
          } else return item;
        }),
      };

    default:
      return state;
  }
};
