export const ERROR = "ERROR";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_USER_PRECAUTIONS = "UPDATE_USER_PRECAUTIONS";
export const UPDATE_USER_STATEMENTS = "UPDATE_USER_STATEMENTS";
export const UPDATE_USER_SYMPTOMS = "UPDATE_USER_SYMPTOMS";
export const UPDATE_USER_CHANGES = "UPDATE_USER_CHANGES";
export const UPDATE_USER_DEPENDENCIES = "UPDATE_USER_DEPENDENCIES";
export const UPDATE_USER_STATE = "UPDATE_USER_STATE";
export const LOCALE_SET = "LOCALE_SET";
export const UPDATE_USER_ADDED_SYMPTOMS = "UPDATE_USER_ADDED_SYMPTOMS";
