import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiStepIcon: {
      text: {
        fill: 'white',
      }
    },
    MuiSlider: {
      mark: {
        height: '0px',
      },
      markActive: {
        height: '0px',
      },
      valueLabel: {
        "& span": {
          "& span": {
            color: '#fdfbf6',
          }
        }
      }
    },
    MuiButton: {
      containedPrimary: {
        color: '#fdfbf6',
        main: '#3bc48b',
      }
    },
  },
  minWidth: "100%",
  palette: {
/*    text: {
      secondary: {
        color: '#fff',
      }
    },*/
    primary: {
      color: '#fdfbf6',
      main: '#3bc48b',
    },
    secondary: {
      color: '#fd0019',
      main: '#3bc48b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fdfbf6',
    },
  },
});

export default theme;
