import React, { useEffect } from "react";
import { Typography, Grid, Button, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { updateUserSymptoms } from "../../store/actions";
import { updateUserAddedSymptoms } from "../../store/actions";
import { TableCheckbox } from "../../components";
import { FormattedMessage } from "react-intl";
import { isMobile } from "react-device-detect";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  buttons: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'flex-start'
  },
  previous: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'flex-start'
  },
  nextContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignContent: 'center',
    alignItems: 'center'
  }
}));

export default function Symptoms(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const dispatch = useDispatch();
  const classes = useStyles();
  const userSymptoms = useSelector((state) => state.user.synopsis[0]);
  const userState = useSelector((state) => state.user);
  const selectedCheckboxes = useSelector(
    (state) => state.user.synopsis[0].checked
  );
  const [selected, setSelected] = React.useState(selectedCheckboxes);
  const [nextButtonText, setNextButtonText] = React.useState(() => {
    if (selected.length !== 0) {
      return <FormattedMessage id="button.next" defaultMessage="Далі" />;
    } else {
      return (
        <FormattedMessage
          id="button.checkAtLeastOneCheckbox"
          defaultMessage="Відзначте хочаб один пункт"
        />
      );
    }
  });
  const [disableCheckboxes, setDisableCheckboxes] = React.useState(false);
  const [currentDayCheckedSymptoms, setCurrentDayCheckedSymptoms] = React.useState([]);
  const [initialSymptoms, setInitialSymptoms] = React.useState(selectedCheckboxes);

  function compareSymptoms(initial, current) {
    let checkedSymptoms = [];
    current.map((name) => {
      let index = initial.indexOf(name);
      if (index === -1) {
        checkedSymptoms.push(name);
      }
    });
    return checkedSymptoms;
  }

  const handleNext = () => {
    let added = compareSymptoms(initialSymptoms, selected);
    dispatch(updateUserSymptoms(selected, 0));
    dispatch(updateUserAddedSymptoms(added, 0));
    isMobile ? props.handleActualStep(1, 6) : props.handleActualStep(3, 6);
    props.handleAllChecked(false);
  };

  const handleBack = () => {
    dispatch(updateUserSymptoms([], 0));
    dispatch(updateUserAddedSymptoms([], 0));
    isMobile ? props.handleActualStep(0, 4) : props.handleActualStep(2, 4);
  };

  const handleShowResults = () => {
    let added = compareSymptoms(initialSymptoms, selected);
    dispatch(updateUserSymptoms(selected, 0));
    dispatch(updateUserAddedSymptoms(added, 0));
    localStorage.setItem("MoniHeal data", JSON.stringify(userState));
    props.handleActualStep(4, 10, 1);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (name === 'common.nothing.above' && selectedIndex === -1) {
      newSelected = [];
      newSelected = newSelected.concat(newSelected, name);
      setSelected(newSelected);
      setDisableCheckboxes(true);
    } else {
      setDisableCheckboxes(false);
      setSelected(newSelected);
    }

    if (newSelected.length !== 0) {
      setNextButtonText(
        <FormattedMessage id="button.next" defaultMessage="Далі" />
      );
    } else {
      setNextButtonText(
        <FormattedMessage
          id="button.checkAtLeastOneCheckbox"
          defaultMessage="Відзначте хочаб один пункт"
        />
      );
    }
  };

  return (
    <div>
      <Box mt={2}>
        <Typography variant="h6" align="center" gutterBottom>
          <FormattedMessage
            id="symptoms.day1.title"
            defaultMessage="День 1 - Відзначте ваші симптоми:"
          />
        </Typography>
        <Typography variant='subtitle1' align='center' gutterBottom>
          <FormattedMessage
            id="symptoms.day1.tip1"
            defaultMessage="Щоб скористатися системою, позначте принаймні 1 симптом, який у вас з'явився у перший день вашого знедужання."
          />
        </Typography>
      </Box>
      <TableCheckbox
        disabled={disableCheckboxes}
        array={userSymptoms.data}
        nextButtonText={nextButtonText}
        handleClick={handleClick}
        selected={selected}
      />
      <React.Fragment>
        <Grid
          container
          className={classes.buttons}
          spacing={1}
        >
            <Grid container item className={classes.previous}>
              <Button onClick={handleBack}>
                <FormattedMessage id="button.previous" defaultMessage="Назад" />
              </Button>
            </Grid>
          <Grid container item spacing={1} className={classes.nextContainer}>
              {!disableCheckboxes && (
                <Grid item>
                  <Button
                    disabled={selected.length === 0}
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    {selected.length === 0 ? (
                      <FormattedMessage
                        id="button.checkAtLeastOneSymptome"
                        defaultMessage="Відзначте хочаб один симптом"
                      />
                    ) : (
                      <FormattedMessage
                        id="button.fillNextDay"
                        defaultMessage="Наступний день"
                      />
                    )}
                  </Button>
                </Grid>
              )
              }
              {selected.length !== 0 && (
                <>
                  {!disableCheckboxes && (
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        align="center"
                        display="inline"
                      >
                        <FormattedMessage id="button.or" defaultMessage="або" />
                      </Typography>
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleShowResults}
                    >
                      <FormattedMessage
                        id="button.showResult"
                        defaultMessage="Дивитись результат"
                      />
                    </Button>
                  </Grid>
                </>
              )}
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
}
