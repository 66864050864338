import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const Footer = () => {
  const useStyles = makeStyles(() => ({
    footer: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center',
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  }));
  const classes = useStyles();

  return (
    <>
      <footer
        className={classes.footer}
      >
        <div className="row">
          <div className="col-md-12 text-center">
            <p className="d-block text-muted">
              {`© ${new Date().getFullYear()} MyHeal`}
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
