import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import IntroPage from "../IntroPage/IntroPage";
import PatientGender from "../PatientInfo/PatientGender";
import PatientAge from "../PatientInfo/PatientAge";
import PatientChronic from "../PatientInfo/PatientChronic";
import PatientTravel from "../PatientInfo/PatientTravel";
import Symptoms from "../Symptoms/Symptoms";
import Symptoms2 from "../Symptoms/Symptoms2";
import Symptoms3 from "../Symptoms/Symptoms3";
import Symptoms4 from "../Symptoms/Symptoms4";
import Symptoms5 from "../Symptoms/Symptoms5";
import Results from "../Results/Results";
import { FormattedMessage } from "react-intl";
import InfoBlock from '../../components/InfoBlock/index'
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: "30px",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    flexWrap: 'wrap',
    padding: theme.spacing(0),
    background: "rgb(245, 252, 250)",
  },
  buttons: {
    size: "large",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    size: "large",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function ScreeningTest() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [actualStep, setActualStep] = React.useState(0);
  const [checked, setChecked] = React.useState(false);
  const [day, setDay] = React.useState(1);
  const theme = useTheme();
  let steps;
  let defaultSteps;

  if (isMobile) {
    steps = [
      "nav.intro",
      "nav.symptoms",
      "nav.result",
    ];

    defaultSteps = [
      "Вступ",
      "Симптоми",
      "Результати",
    ];
  } else {
    steps = [
      "nav.intro",
      "nav.client",
      "nav.events",
      "nav.symptoms",
      "nav.result",
    ];

    defaultSteps = [
      "Вступ",
      "Пацієнт",
      "Передумови",
      "Симптоми",
      "Результати",
    ];
  }

  function handleActualStep(fakeStep, s, d) {
    setActiveStep(fakeStep);
    setActualStep(s);
    setDay(d);
  }

  function handleAllChecked(status) {
    setChecked(status);
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <IntroPage handleActualStep={handleActualStep} />;
      case 1:
        return <PatientGender handleActualStep={handleActualStep} />;
      case 2:
        return <PatientAge handleActualStep={handleActualStep} />;
      case 3:
        return <PatientChronic handleActualStep={handleActualStep} />;
      case 4:
        return <PatientTravel handleActualStep={handleActualStep} />;
      case 5:
        return <Symptoms handleActualStep={handleActualStep} checked={checked} handleAllChecked={handleAllChecked} />;
      case 6:
        return <Symptoms2 handleActualStep={handleActualStep} checked={checked} handleAllChecked={handleAllChecked} />;
      case 7:
        return <Symptoms3 handleActualStep={handleActualStep} checked={checked} handleAllChecked={handleAllChecked} />;
      case 8:
        return <Symptoms4 handleActualStep={handleActualStep} checked={checked} handleAllChecked={handleAllChecked} />;
      case 9:
        return <Symptoms5 handleActualStep={handleActualStep} checked={checked} handleAllChecked={handleAllChecked} />;
      case 10:
        return <Results handleActualStep={handleActualStep} day={day}/>;
      default:
        throw new Error("Неизвестный шаг");
    }
  }

  return (
    <React.Fragment>
      <main className={classes.layout}>
        {isMobile ? (<Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>
                <FormattedMessage
                  id={label}
                  defaultMessage={defaultSteps[index]}
                />
              </StepLabel>
            </Step>
          ))}
        </Stepper>):(<Stepper activeStep={activeStep} className={classes.stepper} >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>
                <FormattedMessage
                  id={label}
                  defaultMessage={defaultSteps[index]}
                />
              </StepLabel>
            </Step>
          ))}
        </Stepper>)}
        <Paper className={classes.paper}>
          <Typography
            variant="h5"
            align="center"
          >
            <FormattedMessage
              id="main.title"
              defaultMessage="Експрес-календар щоденного самотестування на COVID-19"
            />
          </Typography>
          <React.Fragment>{getStepContent(actualStep)}</React.Fragment>
        </Paper>
        {actualStep === 10 ? <InfoBlock optionTwo={true} /> : <InfoBlock optionTwo={false} />}
      </main>
    </React.Fragment>
  );
}
