import React from "react";
import Container from "@material-ui/core/Container";
import { Header, Footer } from "./components";
import ScreeningTest from "./pages/ScreeningTest/ScreeningTest";
import { makeStyles } from "@material-ui/core/styles";
import { IntlProvider } from "react-intl";
import AppLocale from "./i18n/index";
import {  useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up(1280)]: {
      maxWidth: '100%'
    }
  },
}));

export default function App() {
  const classes = useStyles();
  const stateToProps = useSelector(({ locale }) => ({
    lang: locale.lang,
  }));

  return (
    <IntlProvider
      locale={stateToProps.lang}
      messages={AppLocale[stateToProps.lang]}
    >
      <Container className={classes.root}>
        <Header />
        <ScreeningTest />
        <Footer />
      </Container>
    </IntlProvider>
  );
}
