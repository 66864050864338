import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Radio, RadioGroup, FormLabel, FormControl } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    marginBottom: "30px",
    padding: 0,
  },
  paper: {
    maxWidth: "850px",
    minHeight: "550px",
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "1px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  table: {
    width: "615px",
  },
  focused: {
    "&$focused": {
      color: "rgba(0, 0, 0, 0.54)",
    },
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const TableRadio = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.radioValue);
  const handleChange = (event) => {
    props.handleDependencyAnswer(event.target.name, event.target.value);
    setValue(event.target.value);
  };

  return (
    <>
      <div className={classes.root}>
        <FormControl className={classes.table} component="fieldset">
          <FormLabel component="legend" className={classes.focused}>
            <FormattedMessage
            id={props.title}
            defaultMessage={props.titleDefault}
          />
          </FormLabel>
          <RadioGroup
            aria-label="gender"
            name={props.radioGroupName}
            onChange={handleChange}
            value={value}
          >
            {props.array.dependencyList.map((item, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={
                    item
                  }
                  control={<Radio />}
                  label={
                    <FormattedMessage
                      id={item}
                      defaultMessage={props.array.dependencyDefaultName[index]}
                    />
                  }
                  labelPlacement="end"
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};
export default TableRadio;
