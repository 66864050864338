import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {updateUserStatements, updateUserSymptoms} from "../../store/actions";
import { TableCheckbox } from "../../components";
import { Grid, Button, Box, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import {isMobile} from 'react-device-detect';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  buttons: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  previous: {
    justifyContent: 'flex-start'
  },
  next: {
    justifyContent: 'flex-end'
  }
}));

export default function PatientTravel(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const dispatch = useDispatch();
  const classes = useStyles();
  const travelPrecautions = useSelector((state) => state.user.precautions2);
  const selectedCheckboxes = useSelector(
    (state) => state.user.precautions2.checked
  );
  const [selected, setSelected] = React.useState(selectedCheckboxes);
  const [nextButtonText, setNextButtonText] = React.useState(() => {
    if (selected.length !== 0) {
      return <FormattedMessage id="button.next" defaultMessage="Далі" />;
    } else {
      return <FormattedMessage id='button.checkAtLeastOneCheckbox' defaultMessage="Відзначте хочаб один пункт" />;
    }
  });
  const [disableCheckboxes, setDisableCheckboxes] = React.useState(false);



  const handleNext = () => {
    dispatch(updateUserStatements(selected));
    isMobile ? props.handleActualStep(1, 5) : props.handleActualStep(3, 5);
  };

  const handleBack = () => {
    dispatch(updateUserStatements([]));
    isMobile ? props.handleActualStep(1, 3) : props.handleActualStep(2, 3);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (name === 'common.nothing.above' && selectedIndex === -1) {
      newSelected = [];
      newSelected = newSelected.concat(newSelected, name);
      setSelected(newSelected);
      setDisableCheckboxes(true);
    } else {
      setDisableCheckboxes(false);
      setSelected(newSelected);
    }
    if (newSelected.length !== 0) {
      setNextButtonText(<FormattedMessage id="button.next" defaultMessage="Далі" />);
    } else {
      setNextButtonText(<FormattedMessage id='button.checkAtLeastOneCheckbox' defaultMessage="Відзначте хочаб один пункт" />);
    }
  };

  return (
    <>
      <Box my={2}>
        <Typography variant="h6" align="center" gutterBottom>
          <FormattedMessage
            id="patient.travel.title"
            defaultMessage="Дайте відповідь на кілька запитань щодо подорожей та контактів, які ви мали з іншими людьми."
          />
        </Typography>
      </Box>
      <TableCheckbox
        disabled={disableCheckboxes}
        array={travelPrecautions.data}
        handleClick={handleClick}
        selected={selected}
      />
      <Grid
        container
        className={classes.buttons}
      >
        <Grid container item className={classes.previous}>
          <Button onClick={handleBack}>
            <FormattedMessage id="button.previous" defaultMessage="Назад" />
          </Button>
        </Grid>
        <Grid container item className={classes.next}>
          <Button
            disabled={selected.length === 0}
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            {nextButtonText}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
