import React, { useEffect } from "react";
import { Grid, Button, Divider, Box, Typography } from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {updateUserSymptoms} from "../../store/actions";
import {updateUserAddedSymptoms} from "../../store/actions";
import {updateUserDependencies} from "../../store/actions";
import {TableCheckbox} from "../../components";
import {TableRadio} from "../../components";
import {FormattedMessage} from "react-intl";
import {isMobile} from 'react-device-detect';
import {makeStyles} from "@material-ui/core/styles";

let _ = require('lodash/array');

const useStyles = makeStyles((theme) => ({
  buttons: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'flex-start'
  },
  previous: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'flex-start'
  },
  nextContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignContent: 'center',
    alignItems: 'center'
  }
}));

export default function Symptoms2(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const dispatch = useDispatch();
  const classes = useStyles();
  const arrayOfSymptomsConst = useSelector((state) => state.user.synopsis[0].data);
  const userState = useSelector((state => state.user));
  const selectedSymptomsAll = useSelector(
    (state) => {
      let result = []
        .concat(state.user.synopsis[0].checked)
        .concat(state.user.synopsis[1].checked)
        .concat(state.user.synopsis[2].checked);
      result = _.uniq(result);
      return result;
    }
  );
  const selectedSymptomsForRadioAll = useSelector(
    (state) => {
      let result = []
        .concat(state.user.synopsis[0].checked)
        .concat(state.user.synopsis[1].checked);
      result = _.uniq(result);
      return result;
    }
  );
  const [selectedCheckboxes, setSelectedCheckboxes] = React.useState(selectedSymptomsAll);
  const storedCurrentDayDependenciesAnswers = useSelector(
    (state) => state.user.synopsis[2].dependencies
  );
  const [dependenciesAnswers, setDependenciesAnswers] = React.useState(storedCurrentDayDependenciesAnswers);
  const [isEveryDependencyChecked, setIsEveryDependencyChecked]  = React.useState(props.checked);
  const [showAdditionalSymptoms, setAdditionalSymptoms] = React.useState(false);

  const [initialSymptoms, setInitialSymptoms] = React.useState(selectedCheckboxes);

  function compareSymptoms(initial, current) {
    let checkedSymptoms = [];
    current.map((name) => {
      let index = initial.indexOf(name);
      if (index === -1) {
        checkedSymptoms.push(name);
      }
    });
    return checkedSymptoms;
  }

  const handleNext = () => {
    let added = compareSymptoms(initialSymptoms, selectedCheckboxes);
    dispatch(updateUserSymptoms(selectedCheckboxes, 2));
    dispatch(updateUserAddedSymptoms(added, 2));
    dispatch(updateUserDependencies(dependenciesAnswers, 2));
    isMobile ? props.handleActualStep(1, 8) : props.handleActualStep(3, 8);
    props.handleAllChecked(false);
  };

  const handleBack = () => {
    dispatch(updateUserSymptoms([], 2));
    dispatch(updateUserAddedSymptoms([], 2));
    dispatch(updateUserDependencies([], 2));
    isMobile ? props.handleActualStep(1, 6) : props.handleActualStep(3, 6);
    props.handleAllChecked(true);
  };

  const handleShowResults = () => {
    let added = compareSymptoms(initialSymptoms, selectedCheckboxes);
    dispatch(updateUserSymptoms(selectedCheckboxes, 2));
    dispatch(updateUserAddedSymptoms(added, 2));
    dispatch(updateUserDependencies(dependenciesAnswers, 2));
    localStorage.setItem('MoniHeal data', JSON.stringify(userState));
    props.handleActualStep(4, 10, 3);
  };

  const handleAdditionalSymptomsChange = () => {
    showAdditionalSymptoms ? setAdditionalSymptoms(false) : setAdditionalSymptoms(true);
  };

  const handleClickSymptoms = (event, name) => {
    const selectedIndex = selectedCheckboxes.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCheckboxes, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCheckboxes.slice(1));
    } else if (selectedIndex === selectedCheckboxes.length - 1) {
      newSelected = newSelected.concat(selectedCheckboxes.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCheckboxes.slice(0, selectedIndex),
        selectedCheckboxes.slice(selectedIndex + 1)
      );
    }

    setSelectedCheckboxes(newSelected);
  };

  const handleDependencyAnswer = (name, value) => {
    let find = false;
    dependenciesAnswers.map((item) => {
      if (item.name === name) {
        item.value = value;
        find = true
      }
      return item
    });

    if (!find) {
      let newSelectedDependencies = dependenciesAnswers;
      newSelectedDependencies.push({name: name, value: value});
      setDependenciesAnswers(newSelectedDependencies);
    }
    let isEverythingChecked = dependenciesAnswers.length < selectedSymptomsForRadioAll.length;
    setIsEveryDependencyChecked(!isEverythingChecked);
  };

  return (
    <React.Fragment>
      <Box mt={2} mb={4}>
        <Typography variant="h6" align="center" gutterBottom>
          <FormattedMessage id='symptoms.day3.title' defaultMessage="День 3 - Як змінився ваш стан?" />
        </Typography>
      </Box>
      {selectedSymptomsForRadioAll.map((item, index) => {
        let radioGroupCurrentValue;
        let selectedSymptomObject = arrayOfSymptomsConst.find(
          (element) => {
            return element.name === item;
          }
        );
        let selectedSymptomDependencyAnswer = dependenciesAnswers.find(
          (elem) => {
            return elem.name === selectedSymptomObject.name;
          }
        );
        selectedSymptomDependencyAnswer ? radioGroupCurrentValue = selectedSymptomDependencyAnswer.value : radioGroupCurrentValue = false;
        return (<TableRadio
          title={selectedSymptomObject.dependencyQuestion}
          titleDefault={selectedSymptomObject.dependencyQuestionDefault}
          key={index}
          radioGroupName={selectedSymptomObject.name}
          array={selectedSymptomObject}
          radioValue={radioGroupCurrentValue}
          handleDependencyAnswer={handleDependencyAnswer}
        />)
      })}
      <Grid container justify="center" style={{marginBottom: "30px"}}>
        <Button
          style={{background: '#d84c36e0', color: 'white'}}
          variant="contained"
          color="secondary"
          onClick={handleAdditionalSymptomsChange}><FormattedMessage id='button.newSymptomsAppeared' defaultMessage="З'явились нові симптоми" /></Button>
      </Grid>
      <React.Fragment>
        {showAdditionalSymptoms && (
          <React.Fragment>
            <Divider/>
            <Box mt={2} >
              <Typography variant="h6" align="center">
                <FormattedMessage id='symptoms.newSymptoms' defaultMessage='Нові симптоми:' />
              </Typography>
              <Typography variant='subtitle1' align='center' >
                <FormattedMessage id='symptoms.tip1' defaultMessage='Якщо ви відмітили хоча б один із симптомів, система буде опитувати вас щодо їх наявності щоденно.' />
              </Typography>
            </Box>
            <TableCheckbox
              buttons
              array={arrayOfSymptomsConst}
              handleClick={handleClickSymptoms}
              selected={selectedCheckboxes}
            />
          </React.Fragment>
        )}
      </React.Fragment>
      {!showAdditionalSymptoms && (
        <Box mb={3}>
          <Divider />
        </Box>
      )}
      <React.Fragment>
        <Grid
          container
          className={classes.buttons}
          spacing={1}
        >
          <Grid container item className={classes.previous}>
            <Button onClick={handleBack}><FormattedMessage id='button.previous' defaultMessage='Назад' /></Button>
          </Grid>
          <Grid container item spacing={1} className={classes.nextContainer}>
              <Grid item>
                <Button
                  disabled={!isEveryDependencyChecked}
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  justify="center"
                >
                  {!isEveryDependencyChecked ? <FormattedMessage id='button.answerAllQuestions' defaultMessage="Дайте відповідь на всі питання" /> : <FormattedMessage id='button.fillNextDay' defaultMessage="Заповнити наступний день" />}
                </Button>
              </Grid>
              {isEveryDependencyChecked && (<>
                <Grid item>
                  <Typography variant='subtitle1' align='center' display='inline'>
                    <FormattedMessage id='button.or' defaultMessage="або" />
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleShowResults}><FormattedMessage id='button.showResult' defaultMessage='Дивитись результат' /></Button>
                </Grid>
              </>)}
          </Grid>
        </Grid>
      </React.Fragment>
    </React.Fragment>
  );
}
