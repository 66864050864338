import {
  UPDATE_USER_INFO,
  UPDATE_USER_PRECAUTIONS,
  UPDATE_USER_STATEMENTS,
  UPDATE_USER_SYMPTOMS,
  UPDATE_USER_CHANGES,
  UPDATE_USER_DEPENDENCIES,
  UPDATE_USER_STATE,
  UPDATE_USER_ADDED_SYMPTOMS,
} from '../action-types';

export const updateUserState = (payload) => {
  return {
    type: UPDATE_USER_STATE,
    payload,
  };
};

export const updateUserInfo = (payload) => {
  return {
    type: UPDATE_USER_INFO,
    payload,
  };
};

export const updateUserPrecautions = (payload) => {
  return {
    type: UPDATE_USER_PRECAUTIONS,
    payload,
  };
};

export const updateUserStatements = (payload) => {
  return {
    type: UPDATE_USER_STATEMENTS,
    payload,
  };
};

export const updateUserSymptoms = (payload, day) => {
  return {
    type: UPDATE_USER_SYMPTOMS,
    payload,
    day
  };
};

export const updateUserAddedSymptoms = (payload, day) => {
  return {
    type: UPDATE_USER_ADDED_SYMPTOMS,
    payload,
    day
  };
};

export const updateUserDependencies = (payload, day) => {
  return {
    type: UPDATE_USER_DEPENDENCIES,
    payload,
    day
  };
};

export const updateUserChanges = (payload) => {
  return {
    type: UPDATE_USER_CHANGES,
    payload,
  };
};
