import React from "react";
import {Container, Box, Button, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage, FormattedHTMLMessage} from "react-intl";
import ReactPixel from 'react-facebook-pixel';

const useStyles = makeStyles((theme) => ({
  monihealBox: {
    alignContent: 'center',
    justifyContent: "center",
    alignItems: 'center',
    padding: 20,
    border: "solid 1px #3bc48b",
    borderRadius: "16px",
    backgroundColor: "#00800014",
    marginTop: "40px",
    maxWidth: "600px",
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

const InfoBlock = (props) => {
  const classes = useStyles();
  const content1 =
    <div>
      <Box mb={2}>
        <Typography align='center'>
          <FormattedHTMLMessage id='infoblock.content1.text1' defaultMessage='Максимально повні можливості телемедицини у період карантину&nbsp;- реєструйся у MyHeal!' />
        </Typography>
      </Box>
    </div>;
  const content2 = <div><Typography  align='center'>
    <FormattedMessage id='infoblock.content2.text1' defaultMessage='Термінова онлайн-консультація із лікарем, моніторинг стану організму і власний медичний архів -' />
  </Typography>
    <Box mb={2}>
      <Typography  align='center'><FormattedMessage id='infoblock.content2.text2' defaultMessage='скористайтесь усіма можливостями MyHeal!' /></Typography>
    </Box></div>;

  const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
  };

  ReactPixel.init('3531607573535875', options);
  ReactPixel.pageView();

  const handleClick = () => {
    ReactPixel.track( 'ViewContent', {content_name: "Registration Form"} );
  };

  return (
    <React.Fragment>
      <Container className={classes.monihealBox}>
        { props.optionTwo ? content2 : content1 }
        <Box display='flex'>
          <Box m="auto">
            <Button
              onClick={handleClick}
              variant="contained"
              color="primary"
              href="https://moniheal.com/?showModal=register"
            >
              <FormattedMessage id='button.tolearnmore' defaultMessage='Дізнатись більше' />
            </Button>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
    );
};

export default InfoBlock
