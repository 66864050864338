const precautions1 = [
  {
    name: "patient.chronic1",
    defaultName: "Астму чи інші хронічні захворювання органів дихання",
  },
  {
    name: "patient.chronic2",
    defaultName: "Встановлену серцеву недостатність",
  },
  {
    name: "patient.chronic3",
    defaultName: "Наявність злоякісної пухлини",
  },
  {
    name: "patient.chronic4",
    defaultName: "Хронічне захворювання печінки",
  },
  {
    name: "patient.chronic5",
    defaultName: "Хронічна обструктивна хвороба легень",
  },
  {
    name: "patient.chronic6",
    defaultName: "Хронічна хвороба нирок",
  },
  {
    name: "patient.chronic7",
    defaultName: "Отримуєте хіміотерапію чи променеву терапію",
  },
  {
    name: "patient.chronic8",
    defaultName: "Нещодавно пересаджували органи",
  },
  {
    name: "patient.chronic9",
    defaultName: "Маєте діагностований СНІД",
  },
  {
    name: "patient.chronic10",
    defaultName: "Маєте встановлені імунологічні захворювання",
  },
  {
    name: "patient.chronic11",
    defaultName:
      "Неконтрольований високий кров'яний тиск, незважаючи на прийом ліків від артеріального тиску",
  },
  {
    name: "patient.chronic12",
    defaultName:
      "Діабет і відчуваєте ускладнення, незважаючи на прийом ліків",
  },
  {
    name: "patient.chronic13",
    defaultName:
      "Зайва вага",
  },
  { name: "common.nothing.above",
    defaultName: "Нічого з вищезазначеного",
  },
];

export { precautions1 };
