const symptoms = [
  {
    name: "symptoms.name1",
    defaultName: "Підвищена температура (від 37.5) і вище",
    dependencyQuestion: "symptoms.question.name1",
    dependencyQuestionDefault: "Як Ваша температура?",
    dependencyDefaultName: [
      "Температура без змін",
      "Температура підвищилась (більше 39)",
      "Температура стала нижче (меньше 37.5)",
      "Температура нормалізувалась",
    ],
    dependencyList: [
      "symptoms.name1.option1",
      "symptoms.name1.option2",
      "symptoms.name1.option3",
      "symptoms.name1.option4",
    ],
    dependencyWeight: [2, 2, 2, 1],
    dependencyWeightWithChronic: [2, 2, 2, 2],
    weight: 2,
    weightWithChronic: 2,
  },
  {
    name: "symptoms.name2",
    defaultName:
      "Важко дихати (не вистачає повітря, неможливо зробити вдох на повні груди)",
    dependencyQuestion: "symptoms.question.name2",
    dependencyQuestionDefault: "Як змінилось ваше дихання?",
    dependencyDefaultName: [
      "Дихання без змін",
      "Дихати стало важче",
      "Дихання стало легше",
      "Дихання нормалізувалось",
    ],
    dependencyList: [
      "symptoms.name2.option1",
      "symptoms.name2.option2",
      "symptoms.name2.option3",
      "symptoms.name2.option4",
    ],
    dependencyWeight: [4, 4, 1, 1],
    dependencyWeightWithChronic: [4, 4, 3, 2],
    weight: 4,
    weightWithChronic: 4,
  },
  {
    name: "symptoms.name3",
    defaultName:
      "Задуха, що наявна незалежно від фізнавантажень та в стані спокою",
    dependencyQuestion: "symptoms.question.name3",
    dependencyQuestionDefault: "Чи відчуваєте ви задуху?",
    dependencyDefaultName: [
      "Задуха посилилася",
      "Задуха така сама",
      "Задуха майже минула",
      "Задуха минула",
    ],
    dependencyList: [
      "symptoms.name3.option1",
      "symptoms.name3.option2",
      "symptoms.name3.option3",
      "symptoms.name3.option4",
    ],
    dependencyWeight: [4, 2, 1, 1],
    dependencyWeightWithChronic: [4, 3, 2, 2],
    weight: 4,
    weightWithChronic: 4,
  },
  {
    name: "symptoms.name4",
    defaultName:
      "Ускладнення дихання, що посилюється коли ви знаходитесь у гаризонтальному положенні. Виникає бажання сісти.",
    dependencyQuestion: "symptoms.question.name4",
    dependencyQuestionDefault: "Як змінилось ваше дихання?",
    dependencyDefaultName: [
      "Ускладнення дихання посилилось",
      "Ускладнення дихання таке саме",
      "Ускладнення дихання майже пройшло",
      "Ускладнення дихання минуло",
    ],
    dependencyList: [
      "symptoms.name4.option1",
      "symptoms.name4.option2",
      "symptoms.name4.option3",
      "symptoms.name4.option4",
    ],
    dependencyWeight: [4, 4, 1, 1],
    dependencyWeightWithChronic: [4, 4, 2, 2],
    weight: 4,
    weightWithChronic: 4,
  },
  {
    name: "symptoms.name5",
    defaultName: "Сухий кашель",
    dependencyQuestion: "symptoms.question.name5",
    dependencyQuestionDefault: "Як змінився ваш кашель?",
    dependencyDefaultName: [
      "З'явилося мокротиння",
      "Кашель став глибшим (грудний)",
      "Кашель такий самий",
      "Кашель минув",
    ],
    dependencyList: [
      "symptoms.name5.option1",
      "symptoms.name5.option2",
      "symptoms.name5.option3",
      "symptoms.name5.option4",
    ],
    dependencyWeight: [3, 2, 2, 1],
    dependencyWeightWithChronic: [3, 2, 2, 2],
    weight: 1,
    weightWithChronic: 2,
  },
  {
    name: "symptoms.name6",
    defaultName: "Спостерігаються болі у горлі при ковтанні",
    dependencyQuestion: "symptoms.question.name6",
    dependencyQuestionDefault: "Чи відчуваєте ви біль у горлі при ковтанні?",
    dependencyDefaultName: [
      "Боляче ковтати або навіть розмовляти",
      "Біль у горлі легша",
      "Біль у горлі минула",
    ],
    dependencyList: [
      "symptoms.name6.option1",
      "symptoms.name6.option2",
      "symptoms.name6.option3",
    ],
    dependencyWeight: [2, 2, 1],
    dependencyWeightWithChronic: [2, 2, 2],
    weight: 1,
    weightWithChronic: 2,
  },
  {
    name: "symptoms.name7",
    defaultName: "Втома, яка спостерігається тривалий час",
    dependencyQuestion: "symptoms.question.name7",
    dependencyQuestionDefault: "Чи відчуваєте ви втому?",
    dependencyDefaultName: [
      "Втома наростає",
      "Втома відчувається на тому ж рівні",
      "Втома пройшла",
    ],
    dependencyList: [
      "symptoms.name7.option1",
      "symptoms.name7.option2",
      "symptoms.name7.option3",
    ],
    dependencyWeight: [2, 2, 1],
    dependencyWeightWithChronic: [2, 2, 2],
    weight: 2,
    weightWithChronic: 2,
  },
  {
    name: "symptoms.name8",
    defaultName: "Ломота в кістках та біль у м'язах",
    dependencyQuestion: "symptoms.question.name8",
    dependencyQuestionDefault: "Чи відчуваєте ви ломоту в кістках та/або біль у м'язах?",
    dependencyDefaultName: [
      "Ломота в кістках та біль у м'язах загострилась",
      "Ломота в кістках та біль у м'язах проходить",
      "Ломота в кістках та біль у м'язах пройшла",
    ],
    dependencyList: [
      "symptoms.name8.option1",
      "symptoms.name8.option2",
      "symptoms.name8.option3",
    ],
    dependencyWeight: [2, 1, 1],
    dependencyWeightWithChronic: [2, 1, 1],
    weight: 2,
    weightWithChronic: 2,
  },
  {
    name: "symptoms.name9",
    defaultName: "Діарея або здуття кишечника",
    dependencyQuestion: "symptoms.question.name9",
    dependencyQuestionDefault: "Чи супроводжує вас діарея або вздуття кишечника?",
    dependencyDefaultName: [
      "Діарея супроводжує постійно",
      "Діарея супроводжує іноді",
      "Діарея минула",
    ],
    dependencyList: [
      "symptoms.name9.option1",
      "symptoms.name9.option2",
      "symptoms.name9.option3",
    ],
    dependencyWeight: [2, 2, 1],
    dependencyWeightWithChronic: [3, 2, 1],
    weight: 1,
    weightWithChronic: 1,
  },
  {
    name: "symptoms.name10",
    defaultName: "Нудота",
    dependencyQuestion: "symptoms.question.name10",
    dependencyQuestionDefault: "Чи супроводжує вас нудота?",
    dependencyDefaultName: [
      "Нудота тривала, безперервна",
      "Нудота буває інколи",
      "Нудота минула",
    ],
    dependencyList: [
      "symptoms.name10.option1",
      "symptoms.name10.option2",
      "symptoms.name10.option3",
    ],
    dependencyWeight: [4, 2, 1],
    dependencyWeightWithChronic: [4, 2, 1],
    weight: 2,
    weightWithChronic: 2,
  },
  {
    name: "symptoms.name11",
    defaultName:
      "Слабо прощупується пульс, втрата свідомості, порушення серцевого ритму",
    dependencyQuestion: "symptoms.question.name11",
    dependencyQuestionDefault: "Чи відчуваєте ви що слабко прощупується пульс, або втрачаєте свідомість?",
    dependencyDefaultName: [
      "Слабкий пульс та втрата свідомості у стані спокою",
      "Слабкий пульс та аритмія минули",
    ],
    dependencyList: [
      "symptoms.name11.option1",
      "symptoms.name11.option2",
    ],
    dependencyWeight: [4, 1],
    dependencyWeightWithChronic: [4, 2],
    weight: 4,
    weightWithChronic: 4,
  },
  {
    name: "symptoms.name12",
    defaultName: "Закладеність носа",
    dependencyQuestion: "symptoms.question.name12",
    dependencyQuestionDefault: "Чи закладений у вас ніс?",
    dependencyDefaultName: [
      "Закладеність носа посилилась",
      "Закладеність носа така сама",
      "Закладеність носа минула",
    ],
    dependencyList: [
      "symptoms.name12.option1",
      "symptoms.name12.option2",
      "symptoms.name12.option3",
    ],
    dependencyWeight: [2, 2, 1],
    dependencyWeightWithChronic: [2, 2, 2],
    weight: 2,
    weightWithChronic: 2,
  },
  {
    name: "symptoms.name13",
    defaultName: "Втрата нюху і смаку",
    dependencyQuestion: "symptoms.question.name13",
    dependencyQuestionDefault: "Чи повернувся до вас нюх або смак?",
    dependencyDefaultName: [
      "Втрата нюху та/або смаку зберігається",
      "Втрата нюху/або смаку минула",
    ],
    dependencyList: [
      "symptoms.name13.option1",
      "symptoms.name13.option2",
    ],
    dependencyWeight: [2, 1],
    dependencyWeightWithChronic: [2, 1],
    weight: 2,
    weightWithChronic: 2,
  },
  {
    name: "common.nothing.above",
    defaultName: "Нічого з вищезазначеного",
  }
];

export { symptoms };
