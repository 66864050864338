import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    marginBottom: "30px",
    padding: 0,
  },
  paper: {
    maxWidth: "850px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(0.9),
    marginBottom: theme.spacing(0.9),
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const TableCheckbox = (props) => {
  const classes = useStyles();
  const isSelected = (name) => props.selected.indexOf(name) !== -1;
  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={0}>
          <TableContainer className={classes.tableContainer}>
            <Table
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <TableBody>
                {props.array.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={ (props.disabled && row.name !== 'common.nothing.above') ? undefined :
                        (event) => props.handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <Typography variant="subtitle1" component="div">
                          <FormattedMessage
                            id={row.name}
                            defaultMessage={row.defaultName}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={props.disabled && row.name !== 'common.nothing.above'}
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};
export default TableCheckbox;
