const results = [
  {
    title: 'result.emergencyLevel0.title',
    description: 'result.emergencyLevel0.description',
    defaultMessage: 'Скоріш за все, поки що ваші симптоми не є типовими для коронавірусної хвороби. Наразі немає гострої потреби в госпіталізації. На цьому етапі Ви маєте уважно слідкувати за Вашими симптомами і у разі погіршення негайно телефонувати своєму сімейному лікарю.'
  },
  {
    title: 'result.emergencyLevel1.title',
    description: 'result.emergencyLevel1.description',
    defaultMessage: 'Частина Ваших симптомів може свідчити про наявність коронавірусної хвороби. Негайно зверніться до Вашого сімейного лікаря. У разі погіршення стану - викликайте швидку.'
  },
  {
    title: 'result.emergencyLevel2.title',
    description: 'result.emergencyLevel2.description',
    defaultMessage: 'Деякі з наявних у вас симптомів і динаміка розвитку хвороби свідчать про ймовірність небезпеки наявності коронавірусної хвороби. Терміново проконсультуйтесь із вашим сімейним лікарем, також бажано пройти тест на Сovid-19.'
  },
  {
    title: 'result.emergencyLevel3.title',
    description: 'result.emergencyLevel3.description',
    defaultMessage: 'Ваші симптоми відносяться до критичних при коронавірусній хворобі. Негайно зателефонуйте 103, повідомте сімейного лікаря і викликайте швидку допомогу.'
  },
  {
    title: 'result.emergencyLevel4.title',
    description: 'result.emergencyLevel4.description',
    defaultMessage: "Стежте за своїм станом. Поверніться якщо у вас з'являться перші симптоми захворювання"
  }
];

export default { results };
