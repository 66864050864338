import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { Grid, Button, Box, Typography, IconButton, Slider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../../store/actions";
import { FormattedMessage } from "react-intl";
import {isMobile} from 'react-device-detect';

const PrettoSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const marks = [
  {
    value: 30,
    label: "30",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 70,
    label: "70",
  },
];

export default function PatientAge(props) {
  const userAge = useSelector((state) => state.user.age);
  const [age, setAge] = React.useState(() => userAge);
  const dispatch = useDispatch();
  const handleUserInfoChange = (e, age) => {
    setAge(age);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const handleIncrease = () => {
    let newAge;
    age < 120 ? (newAge = age + 1) : (newAge = age);
    setAge(newAge);
  };

  const handleDecrease = () => {
    let newAge;
    age > 18 ? (newAge = age - 1) : (newAge = age);
    setAge(newAge);
  };

  const handleNext = () => {
    dispatch(updateUserInfo({ age }));
    isMobile ? props.handleActualStep(0, 3) : props.handleActualStep(2, 3);
  };

  const handleBack = () => {
    dispatch(updateUserInfo({ age: 30 }));
    isMobile ? props.handleActualStep(0, 1) : props.handleActualStep(1, 1);
  };

  return (
    <React.Fragment>
      <Box mb={7} mt={2}>
        <Typography variant="h6" align="center" gutterBottom>
          <FormattedMessage id="patient.age" defaultMessage="Ваш вік:" />
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item>
          <IconButton
            color="primary"
            aria-label="Уменьшить"
            onClick={handleDecrease}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Grid>
        <Grid item xs>
          <PrettoSlider
            color="primary"
            valueLabelDisplay="on"
            aria-label="Слайдер"
            min={18}
            onChange={handleUserInfoChange}
            value={age}
            max={120}
            marks={marks}
          />
        </Grid>
        <Grid item>
          <IconButton
            color="primary"
            aria-label="Увеличить"
            onClick={handleIncrease}
          >
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <React.Fragment>
        <Grid
          container
          alignItems="flex-end"
          justify="space-between"
          spacing={10}
        >
          <Grid item>
            <Button onClick={handleBack}>
              <FormattedMessage id="button.previous" defaultMessage="Назад" />
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              justify="center"
            >
              <FormattedMessage id="button.next" defaultMessage="Далі" />
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    </React.Fragment>
  );
}
