import React, { useEffect } from "react";
import { Grid, Button, Box, Typography, Checkbox } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import {
  updateUserDependencies,
  updateUserInfo,
  updateUserPrecautions,
  updateUserState,
  updateUserStatements,
  updateUserSymptoms
} from "../../store/actions"
import {makeStyles} from "@material-ui/core/styles";
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  box: {
    border: '2px solid #3bc48b',
    borderRadius: '10px',
    padding: '10px'
    },
}));

export default function IntroPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [terms, setTerms] = React.useState(() => false);
  const [continueTest, setContinueTest] = React.useState( false);
  const [stepNumber, setStepNumber] = React.useState(1);

  const handleClickTerms = () => {
    setTerms(!terms);
  };

  const handleNext = () => {
    isMobile ? props.handleActualStep(0, 1) : props.handleActualStep(1, 1);
  };

  const handleCleanAndNext = () => {
    localStorage.removeItem("MoniHeal data");
    dispatch(updateUserInfo({ gender: "" }));
    dispatch(updateUserInfo({ age: 30 }));
    dispatch(updateUserPrecautions([]));
    dispatch(updateUserStatements([]));
    dispatch(updateUserSymptoms([], 0)); // TODO менять за текущий день
    dispatch(updateUserDependencies([], 0));
    dispatch(updateUserSymptoms([], 1)); // TODO менять за текущий день
    dispatch(updateUserDependencies([], 1));
    dispatch(updateUserSymptoms([], 2)); // TODO менять за текущий день
    dispatch(updateUserDependencies([], 2));
    dispatch(updateUserSymptoms([], 3)); // TODO менять за текущий день
    dispatch(updateUserDependencies([], 3));
    dispatch(updateUserSymptoms([], 4)); // TODO менять за текущий день
    dispatch(updateUserDependencies([], 4));
    handleNext();
  };

   useEffect(() => {
     let localStoredState = localStorage.getItem("MoniHeal data");
     if (localStoredState) {
       setContinueTest(true);
       localStoredState = JSON.parse(localStoredState);
       dispatch(updateUserState(localStoredState));
       let step = stepNumber;
       localStoredState.synopsis.forEach((item) => {
         if (item.dependencies.length !== 0) {
           step++;
         }
       });
       setStepNumber(step);
     }
   }, []);

   const continueContent =
     <>
       <Box my={2} mx={3} className={classes.box}>
         <Typography variant="body1">
           <FormattedMessage id="intro.continueMessage.part1" defaultMessage='Останнього разу ви закінчили тестування на '/>{stepNumber}
           <FormattedMessage id="intro.continueMessage.part2" defaultMessage=' дні. Будь ласка,
           натисніть кнопку "Продовжити тест" щоб побачити інформацію яку ви
           заповнили та продовжити тестування далі, або кнопку "Почати заново",
           якщо хочете почати спочатку.' />
         </Typography>
       </Box>
       <Grid item>
         <Button
           disabled={!terms}
           variant="contained"
           color="primary"
           onClick={handleCleanAndNext}
         >
           <FormattedMessage id="button.startFromBeginning" defaultMessage="Почати заново" />
         </Button>
       </Grid>
       <Grid item>
         <Button
           disabled={!terms}
           variant="contained"
           color="primary"
           onClick={handleNext}
         >
           <FormattedMessage id="button.continue" defaultMessage="Продовжити тест" />
         </Button>
       </Grid>
     </>;

  const firstTimeContent =
    <Grid item>
      <Button
        disabled={!terms}
        variant="contained"
        color="primary"
        onClick={handleNext}
      >
        <FormattedMessage id="button.next" defaultMessage="Далі" />
      </Button>
    </Grid>;

  return (
    <React.Fragment>
      <Box my={2}>
        <Typography variant="h6" align="center" gutterBottom>
          <FormattedMessage
            id="intro.heading"
            defaultMessage="Перш ніж почати"
          />
        </Typography>
      </Box>
      <Typography variant="body1">
        <FormattedMessage
          id="intro.text1"
          defaultMessage="Онлайн тест MyHeal-covid19 дозволяє у реальному часі відстежувати симптоматику і розвиток захворювання, якщо Ваш стан свідчить про можливе зараження на коронавірус."
        />
      </Typography>
      <Box my={2}>
        <Typography variant="body1">
          <FormattedMessage
            id="intro.text1.part2"
            defaultMessage="Залежно від наявних симптомів захворювання - система обробляє відповіді і надає висновок-пораду щодо Ваших подальших кроків. Тест розрахований на перші п'ять днів хвороби з моменту наявності характерних ознак зараження."
          />
        </Typography>
      </Box>
      <Box my={2}>
        <Typography variant="body1">
          <FormattedMessage
            id="intro.text1.part3"
            defaultMessage="Якщо Ви починаєте самотестування, скажімо, на 3-й день після появи відповідних ознак хвороби - введіть симптоми перших днів також. Це допоможе зафіксувати розвиток та відслідкувати зміни в організмі. Навіть якщо Ви починаєте користуватись системою після 5-го дня - пройдіть повний цикл опитування із самого початку нездужання."
          />
        </Typography>
      </Box>
      <Box my={2}>
        <Typography variant="body1">
          <i>
            <FormattedMessage
              id="intro.text2"
              defaultMessage="Пам'ятайте, що:"
            />
          </i>
        </Typography>
      </Box>
      <Typography variant="body1">
        <FormattedMessage
          id="intro.text3"
          defaultMessage="Результат даного обстеження не є повноцінним діагнозом. Самотестування проводиться з інформативною метою. Цей тест не може вважатися кваліфікованим заключенням лікаря. Не використовуйте його в критичних ситуаціях. У разі погіршення самопочуття, не гайте часу, відразу викликайте швидку допомогу!"
        />
      </Typography>

      <Box mb={1} mt={4}>
        <Typography variant="body1">
          {" "}
          <b>
            <FormattedMessage
              id="intro.text4"
              defaultMessage="Увага! Негайно телефонуйте за номером 103, якщо Ви виявили у себе будь-який із цих симптомів:"
            />
          </b>
        </Typography>
      </Box>
      <ul>
        <li>
          <Typography variant="body1">
            {" "}
            <FormattedMessage
              id="intro.symptom1"
              defaultMessage="Постійний, стискаючий біль у грудях"
            />
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            {" "}
            <FormattedMessage
              id="intro.symptom2"
              defaultMessage="Ускладнене дихання"
            />
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            {" "}
            <FormattedMessage
              id="intro.symptom3"
              defaultMessage="Сильне запаморочення або розсiяна увага"
            />
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            {" "}
            <FormattedMessage
              id="intro.symptom4"
              defaultMessage="Невиразна мова (в результаті погіршення стану)"
            />
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            {" "}
            <FormattedMessage
              id="intro.symptom5"
              defaultMessage="Кашель з відхаркуванням крові"
            />
          </Typography>
        </li>
      </ul>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="termsAndConditions"
                value="yes"
                checked={terms}
                onChange={handleClickTerms}
              />
            }
            label={
              <FormattedMessage
                id="intro.accept"
                defaultMessage="Я прочитав(ла), зрозумів(ла) і приймаю Умови використання"
              />
            }
          />
        </Grid>
      </Grid>
      <React.Fragment>
        <Box my={2} mr={2}>
          <Grid container spacing={3}>
            <Grid container justify="flex-end" spacing={3}>
              {continueTest ? continueContent : firstTimeContent}
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    </React.Fragment>
  );
}
