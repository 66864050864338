import React, { useCallback } from "react";
import logo from "../../assets/logo.svg";
import { localeSet } from "../../store/actions";
import { MenuItem, FormControl, Select } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid";
import {isMobile} from 'react-device-detect';

const Header = () => {
  const dispatch = useDispatch();
  const dispatchToProps = {
    localeSet: useCallback((lang) => dispatch(localeSet(lang)), [dispatch]),
  };

  const useStyles = makeStyles((theme) => ({
    paper: {
      marginBottom: '30px',
    },
    paperMobile: {
      marginBottom: '30px',
      width: "auto",
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: "auto",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    grid: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 800,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    logo: {
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    lang: {
      justifyContent: 'flex-end',
      alignContent: 'center',
      alignItems: 'center',
      minWidth: 110,
    },
    image: {
      maxWidth: "100%",
      width: "180px",
    }
  }));

  const classes = useStyles();
  const [language, setLanguage] = React.useState("uk-UKR");

  const handleChange = (event) => {
    setLanguage(event.target.value);
    dispatchToProps.localeSet(event.target.value);
  };
  return (
    <Paper  className={isMobile ? classes.paperMobile : classes.paper}>
        <Grid container className={classes.grid}>
          <Grid container item></Grid>
          <Grid container item className={classes.logo}>
            <a
              href="https://moniheal.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={logo} className={classes.image} alt="" />
            </a>
          </Grid>
          <Grid container item className={classes.lang}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                onChange={handleChange}
              >
                <MenuItem value="uk-UKR">{ isMobile ? 'UA' : 'Українська' }</MenuItem>
                <MenuItem value="ru-UKR">{ isMobile ? 'RU' : 'Русский' }</MenuItem>
                <MenuItem value="en-UKR">{ isMobile ? 'ENG' : 'English' }</MenuItem>
              </Select>
          </Grid>
        </Grid>
    </Paper>
  );
};
export default Header;
