import { LOCALE_SET } from "../action-types";

const initialState = {
  lang: "uk-UKR",
};

export const locale = (state = initialState, action) => {
  switch (action.type) {
    case LOCALE_SET:
      localStorage.lang = action.payload;
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
};
