import React, { useEffect } from "react";
import { Grid, Button, Box, Typography, ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../../store/actions";
import female from "../../assets/female.png";
import male from "../../assets/male.png";
import { FormattedMessage } from "react-intl";
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  checked: {
    "&:focus:not(:active)": {
      background:
        "linear-gradient(45deg, rgba(0,36,0,0.33406866164434523) 0%, rgba(21,227,201,1) 50%, rgba(3,213,255,1) 100%)",
      borderRadius: 4,
      pointerEvents: "none",
    },
    background:
      "linear-gradient(45deg, rgba(0,36,0,0.33406866164434523) 0%, rgba(21,227,201,1) 50%, rgba(3,213,255,1) 100%)",
    borderRadius: 4,
    outline: "none !important",
    position: "relative",
    height: 200,
    width: 160,
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
    },
  },
  image: {
    "&:focus:not(:active)": {
      borderRadius: 4,
      pointerEvents: "none",
    },
    outline: "none !important",
    position: "relative",
    height: 200,
    width: 160,
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
    },
  },
  focusVisible: {
    border: "4px solid rgb(5, 169, 101)",
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    color: theme.palette.common.black,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    top: `${theme.spacing(26)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    transition: theme.transitions.create("opacity"),
  },
  buttons: {
    marginTop: `${theme.spacing(8)}px`,
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  previous: {
    justifyContent: 'flex-start',
  },
  next: {
    justifyContent: 'flex-end',
  },
  genderContainer: {
    justifyContent: "center",
    flexWrap: 'nowrap',
  },
  mainContainer: {
    flexDirection: 'column',
    justifyContent: 'center',

  }
}));

export default function PatientGender(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const classes = useStyles();
  const dispatch = useDispatch();
  const genderStatus = useSelector((state) => state.user.gender);
  const [gender, setGender] = React.useState(() => genderStatus);



  const handleUserInfoChange = (status) => {
    setGender(status);
  };

  const handleNext = () => {
    dispatch(updateUserInfo({ gender: gender }));
    isMobile ? props.handleActualStep(0, 2) : props.handleActualStep(1, 2);
  };

  const handleBack = () => {
    dispatch(updateUserInfo({ gender: "" }));
    props.handleActualStep(0, 0);
  };

  return (
    <>
      <Box my={2}>
        <Typography variant="h6" align="center" gutterBottom>
          <FormattedMessage id="gender.heading" defaultMessage="Ваша стать:" />
        </Typography>
      </Box>
      <Grid container className={classes.mainContainer}>
        <Grid container item spacing={3} className={classes.genderContainer}>
          <Grid item>
            <ButtonBase
              onClick={() => handleUserInfoChange("Чоловік")}
              focusRipple
              className={gender === "Чоловік" ? classes.checked : classes.image}
              focusVisibleClassName={classes.focusVisible}
            >
            <span
              className={classes.imageSrc}
              style={{ backgroundImage: `url(${male})` }}
            />
              <span className={classes.imageBackdrop} />
              <span className={classes.imageButton}>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                className={classes.imageTitle}
              >
                <FormattedMessage id="gender.man" defaultMessage="Чоловік" />
              </Typography>
            </span>
            </ButtonBase>
          </Grid>
          <Grid item>
            <ButtonBase
              onClick={() => handleUserInfoChange("Жінка")}
              focusRipple
              className={gender === "Жінка" ? classes.checked : classes.image}
              focusVisibleClassName={classes.focusVisible}
            >
            <span
              className={classes.imageSrc}
              style={{ backgroundImage: `url(${female})` }}
            />
              <span className={classes.imageBackdrop} />
              <span className={classes.imageButton}>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                className={classes.imageTitle}
              >
                <FormattedMessage id="gender.woman" defaultMessage="Жінка" />
              </Typography>
            </span>
            </ButtonBase>
          </Grid>
        </Grid>
        <Grid
          container
          item
          className={classes.buttons}
          xs={12}
        >
          <Grid container item className={classes.previous} xs={6}>
            <Button onClick={handleBack}>
              <FormattedMessage id="button.previous" defaultMessage="Назад" />
            </Button>
          </Grid>
          <Grid container item className={classes.next} xs={6}>
            <Button
              disabled={gender === ""}
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              <FormattedMessage id="button.next" defaultMessage="Далі" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
