import ruMessages from "./locales/ru_UKR.json";
import ukMessages from "./locales/uk_UKR.json";
import enMessages from "./locales/en_UKR.json";

const AppLocale = {
  'ru-UKR': ruMessages,
  'en-UKR': enMessages,
  'uk-UKR': ukMessages
};

export default AppLocale;
