const precautions2 = [
    {
        name: "patient.travel1",
        defaultName: "Останніми днями хтось із ваших близьких, родичів чи друзів був госпіталізований або знаходився на самоізоляції через важкі респіраторні захворювання?",
    },
    {
        name: "patient.travel2",
        defaultName: "Чи їздили ви за кордон протягом 14 днів до моменту появи симптомів?",
    },
    {
        name: "patient.travel3",
        defaultName: "Чи Ви контактували з людиною, у якої була підозра на інфекцію COVID-19 за останні 14 днів?",
    },
    {
        name: "patient.travel4",
        defaultName: "Чи мешкаєте ви разом або доглядаєте за людиною, у якої підозрюється інфекція COVID-19?",
    },
    {
        name: "patient.travel5",
        defaultName: "Чи контактували ви віч-на-віч більше 15 хвилин з особою, у якої підозрюється COVID-19?",
    },
    {
        name: "patient.travel6",
        defaultName: "Інший тип контакту",
    },
    {
        name: "common.nothing.above",
        defaultName: "Нічого з вищезазначеного",
    }
];

export { precautions2 };
